// eslint-disable-next-line no-shadow
export enum OperationStatusEnum {
  "AUTHENTICATED" = "Пройден 3DS",
  "AUTHENTICATION_FAILED" = "Не успешен 3DS",
  "AUTHENTICATION_REQUIRED" = "Требуется 3DS",
  "AUTHENTICATION_STARTED" = "Начат 3DS",
  "CREATED" = "Создан",
  "ERROR" = "Ошибка",
  "EXPIRED" = "Превышен таймаут",
  "FORM_SHOWED" = "Показана форма",
  "PROCESSING" = "В обработке",
  "THREE_DS_METHOD_CALL_REQUIRED" = "3DS метод",
  "FAILED" = "Отказ",
  "SUCCESS" = "Успешно",
}
