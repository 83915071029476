import { initialPasswordConfig } from "api/initialPasswordConfig";
import { routerPaths } from "appConstants/routerPaths";
import { userRolesConfig } from "appConstants/userRolesConfig";
import Cookies from "packages/js-cookie";
import { PasswordConfigType } from "types/business/PasswordConfigType";

class Api {
  public name!: string;

  public readonly defaultPasswordConfig: PasswordConfigType;

  public constructor() {
    this.defaultPasswordConfig = initialPasswordConfig;
  }

  public readonly setPasswordConfig = (passwordConfig: PasswordConfigType): void => {
    Cookies.set("passwordConfig", JSON.stringify(passwordConfig), { sameSite: "strict" });
  };

  public readonly getPasswordConfig = (): PasswordConfigType => {
    const passwordConfig = Cookies.get("passwordConfig");

    return passwordConfig ? JSON.parse(passwordConfig) : this.defaultPasswordConfig;
  };

  public readonly setRoles = (roles: string[]): void => {
    Cookies.set("userRoles", JSON.stringify(roles), { sameSite: "strict" });
  };

  public readonly setId = (id: string): void => {
    Cookies.set("userId", id, { sameSite: "strict" });
  };

  public readonly setIsLoggedFlag = (value: boolean): string | undefined => {
    const newValue = value ? "TRUE" : "FALSE";

    return Cookies.set("isLoggedFlag", newValue);
  };

  public readonly getIsLoggedFlag = (): boolean => {
    const loggedFlag = Cookies.get("isLoggedFlag");

    if (loggedFlag) {
      return loggedFlag === "TRUE";
    }

    return false;
  };

  public readonly getId = (): string => {
    return Cookies.get("userId") || "0";
  };

  public readonly canGetUsersInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR);
  };

  public readonly canGetPaymentInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_OPERATION);
  };

  public readonly canGetInvoiceInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_INVOICE);
  };

  public readonly canGetRefundInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_REFUND);
  };

  public readonly canGetTransferInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_TRANSFER);
  };

  public readonly canCancelPayment = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.X_CANCEL);
  };

  public readonly canRefundPayment = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.X_REFUND);
  };

  public readonly canConfirmPayment = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.X_CONFIRM);
  };

  public readonly canCreateInvoice = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.W_INVOICE);
  };

  public readonly canExecuteInvoice = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.X_INVOICE);
  };

  public readonly canExecuteTransfer = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.X_TRANSFER);
  };

  public readonly canGetStatisticPage = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_STATISTICS);
  };

  public readonly canGetRewardInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_REWARD);
  };

  public readonly canGetH2HInfo = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.R_H2H);
  };

  public readonly canGetSupportPage = (): boolean => {
    return true;
  };

  public readonly canUsersDetailsPage = (): boolean => {
    return true;
  };

  public readonly canGetAuditPage = (): boolean => {
    const roles = this.getRoles();
    return roles.includes(userRolesConfig.ADMINISTRATOR) || roles.includes(userRolesConfig.SYSTEM);
  };

  public readonly canTerminalsPage = (): boolean => {
    return true;
  };

  public readonly canSupportAdministration = (): boolean => {
    return true;
  };

  public readonly removeToken = (): void => {
    this.setIsLoggedFlag(false);
  };

  public readonly getRedirectRoute = (): string => {
    const arr = [];

    if (this.canGetPaymentInfo()) {
      arr.push(routerPaths.payments);
    }

    if (this.canGetInvoiceInfo()) {
      arr.push(routerPaths.invoices);
    }

    if (this.canGetRefundInfo()) {
      arr.push(routerPaths.refunds);
    }

    if (this.canGetTransferInfo()) {
      arr.push(routerPaths.transfers);
    }

    if (this.canGetStatisticPage()) {
      arr.push(routerPaths.statistic);
    }

    return arr[0];
  };

  private readonly getRoles = (): string[] => {
    const userRoles = Cookies.get("userRoles");

    return userRoles ? JSON.parse(userRoles) : "";
  };
}

export const userService = new Api();
