import { OptionModel } from "model/Option.model";
import { CustomSelectState } from "service/shared/others/CustomSelectService/CustomSelect.state";
import { CustomInputEvent } from "types/commonExtend/CustomInputEvent";

export class CustomSelectController {
  public readonly state: CustomSelectState;

  public constructor(state: CustomSelectState) {
    this.state = state;
  }

  public readonly clearForm = (): void => {
    this.state.searchValue.next("");
  };

  public readonly onChangeInput = (event: CustomInputEvent): void => {
    const { value } = event.target;
    this.state.searchValue.next(value);
  };

  public readonly onFocusInput = (): void => {
    this.state.isDropDownOpened.next(true);
  };

  public readonly onClickEmitter = (): void => {
    this.state.isDropDownOpened.next(false);
  };

  public readonly onChangeHandler = (value: OptionModel): void => {
    this.state.onChange(value);
    this.state.searchValue.next("");
    this.state.isDropDownOpened.next(false);
  };
}
