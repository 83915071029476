import { OptionModel } from "model/Option.model";
import { CustomSelectController } from "service/shared/others/CustomSelectService/CustomSelect.controller";
import { CustomSelectState } from "service/shared/others/CustomSelectService/CustomSelect.state";

export class CustomSelectService {
  public readonly state: CustomSelectState;

  public readonly controller: CustomSelectController;

  public constructor(optionList: OptionModel[], onChange: (value: OptionModel) => void) {
    this.state = new CustomSelectState(optionList, onChange);
    this.controller = new CustomSelectController(this.state);
  }
}
