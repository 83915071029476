import { client } from "api/client";
import { userService } from "api/userService";
import { apiPaths } from "appConstants/apiPaths";

import { appState } from "./app.state";

class Controller {
  public readonly state = appState;

  public readonly setPasswordConfig = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.dictionaries);
    const { passwordRules } = data;

    userService.setPasswordConfig(passwordRules);
  };

  public readonly loadBankData = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.sbpMembers);
    this.state.bankData.next(data);
  };

  public readonly onMount = async (): Promise<void> => {
    this.setPasswordConfig();
  };
}

export const appController = new Controller();
