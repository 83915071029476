import { t } from "i18n";
import { InvoiceInputService } from "service/shared/others/InvoiceInputService/InvoiceInput.service";

import { OptionModel } from "../../../../model/Option.model";
import { InvoiceSelectService } from "../../others/InvoiceSelectService/InvoiceSelect.service";

export const initialFields = [
  new InvoiceInputService(
    1,
    t("invoiceCreateFormService.emailTitle"),
    t("invoiceCreateFormService.emailErrorMessage"),
    "email",
    "invoiceCreateFormService.emailTitle",
    "invoiceCreateFormService.emailErrorMessage",
    {
      isEmail: true,
    }
  ),
  new InvoiceInputService(
    2,
    t("invoiceCreateFormService.phoneTitle"),
    t("invoiceCreateFormService.phoneErrorMessage"),
    "phone",
    "invoiceCreateFormService.phoneTitle",
    "invoiceCreateFormService.phoneErrorMessage",
    {
      isPhone: true,
    }
  ),
  new InvoiceInputService(
    3,
    t("invoiceCreateFormService.titleTitle"),
    t("invoiceCreateFormService.titleErrorMessage"),
    "title",
    "invoiceCreateFormService.titleTitle",
    "invoiceCreateFormService.titleErrorMessage",
    {
      isTitle: true,
    }
  ),
  new InvoiceInputService(
    4,
    t("invoiceCreateFormService.descriptionTitle"),
    t("invoiceCreateFormService.descriptionErrorMessage"),
    "description",
    "invoiceCreateFormService.descriptionTitle",
    "invoiceCreateFormService.descriptionErrorMessage",
    {
      isDescription: true,
    }
  ),
  new InvoiceInputService(
    5,
    t("invoiceCreateFormService.amountTitle"),
    t("invoiceCreateFormService.amountErrorMessage"),
    "amount",
    "invoiceCreateFormService.amountTitle",
    "invoiceCreateFormService.amountErrorMessage",
    {
      isNumber: true,
    }
  ),
  new InvoiceSelectService(
    6,
    t("invoiceCreateFormService.paymentTypeTitle"),
    t("invoiceCreateFormService.paymentTypeErrorMessage"),
    "paymentType",
    "invoiceCreateFormService.paymentTypeTitle",
    "invoiceCreateFormService.paymentTypeErrorMessage",
    [
      new OptionModel("invoiceCreateFormService.oneStagePaymentTypeTitle", "ONE_STAGE_PAYMENT_TYPE"),
      new OptionModel("invoiceCreateFormService.twoStagePaymentTypeTitle", "TWO_STAGE_PAYMENT_TYPE"),
    ]
  ),
];
